<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="8"
      sm="8"
    >
      <v-text-field
        v-if="clase.equipaje_bodega.maletas[pos].incluida && clase.equipaje_bodega.maletas[pos].permitida"
        :label="$t('lbl.incluida')"
        hide-details
        dense
        outlined
        disabled
      ></v-text-field>
      <v-text-field
        v-else-if="!clase.equipaje_bodega.maletas[pos].incluida && !clase.equipaje_bodega.maletas[pos].permitida"
        :label="$t('lbl.noPermitida')"
        hide-details
        dense
        outlined
        disabled
      ></v-text-field>
      <v-text-field
        v-else
        v-model="clase.equipaje_bodega.maletas[pos].price"
        type="number"
        min="0"
        hide-details
        dense
        outlined
        prefix="$"
        disabled
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="4"
      sm="4"
    >
      <v-switch
        v-model="clase.equipaje_bodega.maletas[pos].incluida"
        :label="$t('lbl.incluida')"
        dense
        hide-details
        class="mb-0 mt-0 ml-2"
        disabled
      ></v-switch>
      <v-switch
        v-model="clase.equipaje_bodega.maletas[pos].permitida"
        :label="$t('lbl.permitida')"
        dense
        hide-details
        class="mb-0 mt-0 ml-2"
        disabled
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      icons: {
        mdiTrashCan,
      },
    }
  },
  methods: {
    ...mapMutations(['deleteMaletaBodegaVuelo']),
  },
}
</script>
