<template>
  <fragment>
    <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
      <v-menu
        v-model="menuFrom"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedFromDateFormatted"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.from')"
            persistent-hint
            readonly
            v-bind="attrs"
            outlined
            dense
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="suplemento.from"
          no-title
          :min="new Date(Date.now()).toISOString().substr(0, 10)"
          locale="es"
          :show-current="false"
          @input="
            menuFrom = false
            activeToDate()
          "
        ></v-date-picker>
      </v-menu>
    </td>
    <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
      <v-menu
        v-if="suplemento.from"
        v-model="menuTo"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedToDateFormatted"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.to')"
            persistent-hint
            readonly
            v-bind="attrs"
            outlined
            dense
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="suplemento.to"
          no-title
          locale="es"
          :show-current="false"
          :min="
            $moment(suplemento.from)
              .add(1, 'day')
              .toDate()
              .toISOString()
              .substr(0, 10)
          "
          @input="menuTo = false"
        ></v-date-picker>
      </v-menu>
    </td>
  </fragment>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiMenuDown, mdiMenuRight } from '@mdi/js'

export default {
  props: {
    showRow: {
      type: Boolean,
    },
    // eslint-disable-next-line vue/require-default-prop
    suplemento: {
      type: Object,
    },
    indSuplemento: {
      type: Number,
      default: 0,
    },
    indexRow: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      model: {
        check: false,
      },
      tarifas: [],
      tarifasXKm: [],
      menuFrom: false,
      menuTo: false,
    }
  },
  computed: {
    ...mapState({
      suplementosContrateCar: state => state.app.suplementosContrateCar,
      categoryContrateCar: state => state.app.categoryContrateCar,
      marcasModeloContrateCar: state => state.app.marcasModeloContrateCar,
    }),
    computedFromDateFormatted() {
      return this.suplemento.from ? this.$moment(this.suplemento.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.suplemento.to ? this.$moment(this.suplemento.to).format('D-MMM-YY') : ''
    },
  },
  methods: {
    ...mapMutations([
      'addSuplementoContrateCar',
      'deleteSuplementoContrateCar',
      'addPriceTarifaXKmSuplementoContrateCar',
      'deletePriceTarifaXKmSuplementoContrateCar',
    ]),
    activeToDate() {
      setTimeout(() => {
        this.menuTo = true
      }, 100)
    },
  },
}
</script>
