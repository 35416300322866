<template>
  <fragment>
    <tr>
      <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
        <v-checkbox
          v-model="model.check"
          :disabled="$store.state.app.onlyShow"
          hide-details
          class="my-0"
          @change="changeCheck()"
        ></v-checkbox>
      </td>
      <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
        {{ suplemento.name }}
      </td>

      <fragment
        v-if="suplemento.name.toLowerCase() !== 'tarifa de retorno' && suplemento.name.toLowerCase() !== 'combustible'"
      >
        <td>{{ suplemento.modalidad }}</td>
      </fragment>

      <fragment v-if="model.check">
        <fragment
          v-for="(suplementoFlight, indSuplementoCar) in suplementosContrateFlight"
          :key="indSuplementoCar"
        >
          <fragment v-if="suplementoFlight.suplemento_id === suplemento.id">
            <td
              v-if="
                suplemento.name.toLowerCase() !== 'tarifa de retorno' && suplemento.name.toLowerCase() !== 'combustible'
              "
            >
              <v-text-field
                v-model="suplementoFlight.price"
                :disabled="$store.state.app.onlyShow"
                outlined
                dense
                hide-details
                prefix="$"
                type="number"
                width="auto"
              ></v-text-field>
            </td>

            <!--VALIDEZ-->
            <SuplementosDates
              :show-row="showRow"
              :ind-suplemento="indSuplemento"
              :index-row="indexRow"
              :suplemento="suplementoFlight"
            />
            <!--<td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
              <vc-date-picker
                v-model="suplementoFlight.from"
                outlined
                dense
                :min-date="new Date()"
                mode="date"
                :model-config="modelConfig"
                width="auto"
                @input="activeToDate"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    :label="$t('lbl.from')"
                    outlined
                    dense
                    :value="inputValue"
                    v-on="inputEvents"
                  >
                  </v-text-field>
                </template>
              </vc-date-picker>
            </td>
            <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
              <vc-date-picker
                v-if="suplementoFlight.from"
                v-model="suplementoFlight.to"
                outlined
                dense
                :min-date="
                  $moment(suplementoFlight.from)
                    .add(1, 'day')
                    .toDate()
                "
                mode="date"
                width="auto"
                :model-config="modelConfig"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field
                    ref="dateTo"
                    :label="$t('lbl.to')"
                    outlined
                    dense
                    :value="inputValue"
                    v-on="inputEvents"
                  >
                  </v-text-field>
                </template>
              </vc-date-picker>
            </td>-->
            <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
              <v-switch
                v-model="suplementoFlight.online"
                :disabled="$store.state.app.onlyShow"
                hide-details
                class="mt-0"
              ></v-switch>
            </td>
            <td :style="showRow && indSuplemento === indexRow ? 'border: 0px solid black;' : ''">
              <v-switch
                v-model="suplementoFlight.obligatorio"
                :disabled="$store.state.app.onlyShow"
                hide-details
                class="mt-0"
              ></v-switch>
            </td>
          </fragment>
        </fragment>
      </fragment>
      <fragment v-else>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </fragment>

      <!---->
    </tr>
  </fragment>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { mdiDeleteOutline, mdiMenuDown, mdiMenuRight } from '@mdi/js'
import SuplementosDates from './SuplementosDates.vue'

export default {
  components: {
    SuplementosDates,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    suplemento: {
      type: Object,
    },
    indSuplemento: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuRight,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      model: {
        check: false,
      },
      showRow: false,
      indexRow: -1,
      tarifas: [],
      tarifasXKm: [],
    }
  },
  computed: {
    ...mapState({
      suplementosContrateFlight: state => state.app.suplementosContrateFlight,
    }),
  },
  mounted() {
    this.suplementosContrateFlight.forEach(element => {
      if (element.suplemento_id === this.suplemento.id) {
        this.model.check = true
      }
    })
  },
  methods: {
    ...mapMutations(['addSuplementoContrateFlight', 'deleteSuplementoContrateFlight']),
    activeToDate() {
      setTimeout(() => {
        this.$refs.dateTo[0].focus()
      }, 100)
    },
    changeCheck() {
      if (this.model.check) {
        this.addSuplementoContrateFlight({
          from: null,
          to: null,
          suplemento_id: this.suplemento.id,
          price: 0,
          obligatorio: false,
          online: false,
        })
      } else {
        this.showRow = !this.showRow
        this.indexRow = -1
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.suplementosContrateFlight.length; index++) {
          const element = this.suplementosContrateFlight[index]

          if (element.suplemento_id === this.suplemento.id) {
            this.deleteSuplementoContrateFlight(index)
            break
          }
        }
      }
    },
    showItem() {
      if (this.indexRow === this.indSuplemento) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = this.indSuplemento
    },
  },
}
</script>
