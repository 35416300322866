<template>
  <v-text-field
    v-model="clase.equipaje_bodega.tasa_despacho"
    type="number"
    min="0"
    hide-details
    dense
    outlined
    :prefix="`$/Eq`"
    disabled
  ></v-text-field>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
  },
}
</script>
