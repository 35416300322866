<template>
  <v-row no-gutters>
    <v-col
      cols="12"
      md="12"
      sm="12"
    >
      <v-text-field
        v-model="clase.price_child"
        :disabled="$store.state.app.onlyShow"
        :label="$t('lbl.price_child')"
        type="number"
        min="0"
        hide-details
        dense
        outlined
        prefix="$"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
  },
}
</script>
