<template>
  <v-select
    v-if="!isLoading"
    v-model="clase.equipaje_bodega.peso"
    :items="pesos"
    item-text="name"
    item-value="id"
    outlined
    dense
    hide-details
    :suffix="clase.equipaje_bodega.um_peso"
    disabled
    @change="changeSelect()"
  ></v-select>
</template>

<script>

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clase: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      sufijo: null,
      pesos: [],
    }
  },

  created() {
    this.load()
  },
  methods: {

    load() {
      this.axios.get(`nom_peso_equipaje_flights?perfil_slug=${sessionStorage.getItem('perfil')}&tipo=bodega`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
        },
      })
        .then(res => {
          const result = res.data.data
          result.forEach(element => {
            if (element.tipo === 'Equipaje facturado') {
              this.pesos.push({
                id: element.id,
                name: `${element.name}`,
                um: element.um,
              })
            }
          })
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    changeSelect() {
      this.clase.equipaje_bodega.um_peso = null
      if (this.clase.equipaje_bodega.peso) {
        this.clase.equipaje_bodega.um_peso = this.pesos.filter(e => e.id === this.clase.equipaje_bodega.peso)[0].um
        this.$store.state.app.umPesoBodega = this.clase.equipaje_bodega.um_peso
      }
    },
  },
}
</script>
